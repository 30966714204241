// Login Header Start
.pd_onboarding_wrapper {
  @extend %theme-flex;
  flex-direction: column;
  min-height: 100vh;
}

.pd_login_header {
  padding: 30px 45px;
  border-bottom: 1px solid #eee;
}

.pd_login_logo {
  h3 {
    font-size: 30px;
    line-height: 40px;
    font-weight: $ft_bold_700;
    color: $black-color;
  }
}

// Login Header End
// Home Page Header Start
.pd_home_header {
  padding: 15px 0;
  border-bottom: 1px solid #eee;
}

.pd_home_logo {
  h3 {
    font-size: 30px;
    line-height: 40px;
    font-weight: $ft_bold_700;
    color: $black-color;
    cursor: pointer;
  }
}

.pd_home_header_right {
  @extend %theme-flex;
}

.pd_navigation_wrapper {
  align-self: center;
  margin-left: auto;

  .navbar {
    padding: 0;
  }

  .nav-item {
    .nav-link {
      font-size: 16px;
      line-height: 28px;
      font-weight: $ft_medium_500;
      color: $heading-color;
      padding: 0 20px;
    }
  }
}

.pd_header_signup_btn {
  ul {
    @extend %theme-flex;

    li {
      a {
        display: inline-block;
        font-family: $body-font;
        background: $bg-primary-color;
        color: $black-color;
        padding: 11px 20px;
        min-width: 135px;
        line-height: 26px;
        text-transform: inherit;
        border: 1px solid $bg-primary-color;
        font-size: 16px;
        font-family: $body-font;
        font-weight: $ft_bold_700;
        text-align: center;

        @include transition();
        outline: none;

        &:focus {
          outline: none;
        }

        &:hover {
          color: $white-color;
          background: $black-color;
          @include transition();
        }
      }
    }
  }
}
.pd_header_profile {
  align-self: center;
  button {
    margin-left: 10px;
  }
  .dropdown-menu {
    top: 0px !important;
    right: 0 !important;
    left: auto !important;
    padding: 0 !important;
  }
  .dropdown-item {
    padding: 0.25rem 1rem;
    font-size: 14px;
  }
}
.MuiMenu-list {
  min-width: 120px !important;
  hr {
    display: none;
  }
}
.pd_header_signup_btn {
  margin-left: auto;
}
.pd_header_profile {
  margin-left: auto;
  .toggle_btn {
    background-color: transparent !important;
    color: $black-color !important;
    border: 0;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    column-gap: 5px;
    outline: none;
    &:focus {
      box-shadow: none !important;
      outline: none;
      border: 0;
    }
  }
}
.logout_wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
// Home Page Header End
.pd_home_header {
  .page-title {
    color: var(--kadenz-black, #000);
    font-family: Inter;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px;
  }
  .right-cont {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
}

.main-header {
  .navbar {
    padding: 9.5px 30px;
    max-height: 100px;
  }

  .account_profile {
    display: flex;
    align-items: center;
    .toggle_btn {
      background-color: transparent;
      color: #000;
      border: 0;
      padding: 0 8px;
      position: relative;
      top: 3px;
      &:focus {
        box-shadow: none;
      }
    }
    img {
      margin-right: 15px !important;
      height: 27px;
      width: 27px;
      border-radius: 50%;
      position: relative;
      top: -2px;
    }
    .dropdown {
      display: flex;
      align-items: center;
      p {
        font-size: 18px;
        line-height: 16px;
        font-weight: 700;
        margin-bottom: 0px;
      }
    }
  }

  .account_prowrap {
    display: flex;
    align-items: center;
  }

  /* Media */

  @media (min-width: 992px) {
    transition: margin-left 0.3s ease-in-out;
    margin-left: 280px;
  }
}


.main-header .account_profile .toggle_btn.active {
  background: transparent;
  color: #000;
  box-shadow: none !important;
}

