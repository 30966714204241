@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;600;700;800;900&display=swap");
$body-font: "Inter", sans-serif;
$body-font-size: 16px;
// Font Weights
$ft_black_900: 900;
$ft_extra_bold_800: 800;
$ft_bold_700: 700;
$ft_semibold_600: 600;
$ft_medium_500: 500;
$ft_regular_400: 400;
$ft_light_300: 300;

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  font-size: $body-font-size;
  font-family: $body-font;
  font-weight: $ft_regular_400;
  color: $heading-color;
  margin: 0;
  padding-right: 0 !important;
  line-height: 24px;
  overflow-x: hidden;
  letter-spacing: normal;
  @include transition();
}
// Font Size Define
h1,
h2,
h3,
h4,
h5,
h6 {
  color: $heading-color;
  font-family: $body-font;
  font-weight: $ft_bold_700;
  line-height: 1.2;
}

$-h1: 46px;
$-h2: 30px;
$-h3: 28px;
$-h4: 18px;
$-h5: 16px;
$-h6: 14px;
$para-font: 16px;
$border-rad5: 5px;

h1 {
  font-size: $-h1;
  margin: 0;
}
h2 {
  font-size: $-h2;
  margin: 0;
}
h3 {
  font-size: $-h3;
  margin: 0;
}
h4 {
  font-size: $-h4;
  margin: 0;
}
h5 {
  font-size: $-h5;
  margin: 0;
}
h6 {
  font-size: $-h6;
  margin: 0;
}
p {
  font-size: $para-font;
  margin: 0;
}
i {
  &:before {
    margin-left: 0 !important;
  }
}
a {
  font-family: $body-font;
  color: $heading-color;
  font-weight: $ft_regular_400;
  // text-transform: capitalize;
  text-decoration: none;
  @include transition();
  &:hover {
    color: $bg-primary-color;
    text-decoration: none;
    @include transition();
  }
}
ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
input {
  &:focus {
    box-shadow: none;
    outline: none;
  }
}
// Button css
.theme_primary_btn {
  display: inline-block;
  font-family: $body-font;
  background: $bg-primary-color;
  color: $black-color;
  padding: 10px 20px;
  min-width: 200px;
  line-height: 28px;
  text-transform: inherit;
  border: 1px solid $bg-primary-color;
  font-size: 18px;
  font-family: $body-font;
  font-weight: $ft_bold_700;
  text-align: center;
  @include transition();
  outline: none;
  &:focus {
    outline: none;
  }
  &:hover {
    color: $white-color;
    background: $black-color;
    @include transition();
  }
}
// .rr_primary_border_btn {
//   background: transparent;
//   color: $text-color;
//   padding: 13px 20px;
//   line-height: 21px;
//   text-transform: inherit;
//   border: 1px solid $text-color;
//   font-size: 16px;
//   font-family: $body-font;
//   font-weight: $ft_bold_700;
//   border-radius: 5px;
//   outline: none;
//   -webkit-transition: $transition;
//   -o-transition: $transition;
//   -ms-transition: $transition;
//   -moz-transition: $transition;
//   transition: $transition;
//   &:focus{
//     outline: none;
//   }
//   &:hover{
//     color: $text-color;
//     background: $primary;
//     border: 1px solid $primary;
//     -webkit-transition: $transition;
//     -o-transition: $transition;
//     -ms-transition: $transition;
//     -moz-transition: $transition;
//     transition: $transition;
//   }
// }
// margin
.mt-0 {
  margin-top: 0;
}
.mt-5 {
  margin-top: 5px !important;
}
.mt-10 {
  margin-top: 10px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-25 {
  margin-top: 25px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-50 {
  margin-top: 50px;
}

.mb-10 {
  margin-bottom: 10px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-50 {
  margin-bottom: 50px;
}

// padding
.ptt-5 {
  padding-top: 5px;
}
.ptt-10 {
  padding-top: 10px;
}
.ptt-15 {
  padding-top: 15px;
}
.ptt-20 {
  padding-top: 20px;
}
.ptt-30 {
  padding-top: 30px;
}
.ptt-40 {
  padding-top: 40px;
}
.ptt-50 {
  padding-top: 50px;
}

.pbb-5 {
  padding-bottom: 5px;
}
.pbb-10 {
  padding-bottom: 10px;
}
.pbb-20 {
  padding-bottom: 20px;
}
.pbb-30 {
  padding-bottom: 30px;
}
.pbb-40 {
  padding-bottom: 40px;
}
.pbb-50 {
  padding-bottom: 50px;
}

// Form css

.pd_lable {
  font-size: 16px;
  line-height: 26px;
  font-weight: $ft_regular_400;
  color: $heading-color;
  margin-bottom: 10px;
}
.pd_input_field {
  width: 100%;
  height: 50px;
  border: 1px solid $black-color;
  padding: 0 15px;
  color: $black-color;
  font-size: 16px;
  font-weight: $ft_regular_400;
}
