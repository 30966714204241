@media (max-width: 1800px) {
  .data_title {
    font-size: 26px;
    line-height: 32px;
  }
  .data_value {
    font-size: 46px;
    line-height: 52px;
  }
}

@media (max-width: 1530px) {
  .data_card {
    padding: 20px 20px;
  }
  .data_title {
    font-size: 22px;
    line-height: 28px;
  }
  .data_value {
    font-size: 42px;
    line-height: 48px;
  }
  .pd_home_header .page-title {
    font-size: 32px;
  }
  .main_article h1 {
    font-size: 40px;
    line-height: 56px;
    margin-left: 20px;
  }
  .main_article h6 {
    font-size: 32px;
    line-height: 42px;
  }
  .label_modify {
    font-size: 20px;
    line-height: 26px;
  }
  .adduser_formwrap .main_article h6 {
    font-size: 36px;
    line-height: 52px;
  }
  .main .content.hide_side .row {
    margin: 0px;
  }
}

@media (max-width: 1366px) {
  .data_title {
    font-size: 20px; //22
    line-height: 32px;
  }
  .data_value {
    font-size: 40px;
    font-weight: 700;
    line-height: 46px;
  }
  .top-wrapper button {
    font-size: 14px;
  }
}

@media (max-width: 1300px) {
  .pd_inner_container {
    padding: 0 15px;
  }
  .pd_container {
    padding: 0 15px;
  }
}
@media (max-width: 1199px) {
  .pd_login_header {
    padding: 15px;
  }
  .pd_home_header {
    padding: 25px 0;
  }
  .pd_header_signup_btn ul li a {
    padding: 7px 20px;
    min-width: 120px;
    font-size: 18px;
  }
  .pd_research_box h3 {
    font-size: 22px;
    line-height: 33px;
  }
  .pd_research_box p {
    font-size: 22px;
    line-height: 33px;
  }
  .pd_results_right_content {
    padding-left: 30px;
  }
  .pd_results_search {
    padding-left: 460px;
  }
  .pd_results_search input {
    width: 300px;
  }
  .pd_results_search button {
    width: 130px;
  }
  .pd_results_box {
    margin-bottom: 50px;
  }
  .pd_fp_research_wrapper {
    padding: 80px 0;
  }
  .pd_fp_research_heading h3 {
    font-size: 22px;
    line-height: 38px;
    margin-bottom: 0px;
  }
  .pd_fp_chatting_wrapper {
    padding-top: 0;
  }
  .pd_fp_chatting_box h3 {
    font-size: 26px;
    line-height: 36px;
  }
  .pd_fp_chatting_box p {
    font-size: 26px;
    line-height: 36px;
  }
  .pd_fp_chatting_wrapper {
    padding-bottom: 80px;
  }
  .pd_ap_research_wrapper {
    padding-top: 0;
    padding-bottom: 80px;
  }
  .pd_article_inner .pd_article_left {
    padding-right: 20px;
  }
  .pd_author_bio {
    padding-top: 25px;
  }
  .pd_research_slider .slick-slider {
    margin: 0 -7.5px;
  }
  .pd_research_slider .slick-slide {
    padding: 0 7.5px;
  }
  .pd_research_slider .slick-slider .slick-prev {
    width: 50px;
    height: 50px;
  }
  .pd_research_slider .slick-slider .slick-next {
    width: 50px;
    height: 50px;
  }
  .pd_research_slider .slick-slider .slick-prev::before {
    width: 50px;
    height: 50px;
    background-size: 50px;
  }
  .pd_research_slider .slick-slider .slick-next::before {
    width: 50px;
    height: 50px;
    background-size: 50px;
  }
  .pd_dashboard_wrapper {
    padding: 60px 0;
  }
  .pd_arp_wrapper {
    padding: 60px 0;
  }
  // -----updates Media---
  .data_title {
    font-size: 13px;
    line-height: 25px;
  }
  .data_value {
    font-size: 30px;
    line-height: 36px;
  }
  .data_title {
    font-size: 18px;
    min-height: 50px;
  }
  .pd_home_header .page-title {
    font-size: 30px;
  }
  .profile_detail_wrap .main_article {
    margin-bottom: 20px !important;
  }
  .pd_login_footer {
    margin-left: 280px;
  }
  .pd_footer_title h2 {
    font-size: 14px;
  }
}
@media (max-width: 991px) {
  .pd_login_header {
    padding: 15px 0;
  }
  .pd_login_row {
    margin: 0 -15px;
  }
  .pd_login_row .col-md-6,
  .pd_login_row .col-md-12 {
    padding: 0 15px;
  }
  .pd_login_heading {
    padding-top: 60px;
    margin-bottom: 0;
  }
  .pd_login_heading p {
    font-size: 22px;
    line-height: 30px;
  }
  .pd_navigation_wrapper .navbar-toggler {
    background-color: #eee;
  }
  .pd_navigation_wrapper {
    order: 2;
    margin-left: auto;
  }
  .pd_header_profile {
    margin-left: auto;
  }
  .pd_research_wrapper .row {
    margin: 0 -10px;
  }
  .pd_research_wrapper .row .rs_col {
    padding: 0 10px;
  }
  .pd_results_filter {
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 0;
  }
  .pd_results_search {
    padding-left: 0;
  }
  .pd_filter_header h3,
  .pd_filter_date h3 {
    font-size: 20px;
    line-height: 33px;
  }
  .pd_filter_date {
    margin-top: 35px;
    padding-right: 0;
  }
  .pd_results_right_content {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 0;
    border-left: 0;
  }
  .pd_results_box h3,
  .pd_results_box p,
  .pd_results_box h5,
  .pd_results_box h6 {
    font-size: 20px;
    line-height: 30px;
  }
  .pd_fp_research_wrapper {
    padding: 60px 0;
  }
  .pd_fp_research_heading h3 {
    font-size: 22px;
    line-height: 38px;
    margin-bottom: 0;
  }
  .pd_fp_research_box h3 {
    font-size: 23px;
  }
  .pd_fp_research_box h4,
  .pd_fp_research_box h5 {
    font-size: 23px;
  }
  .pd_fp_research_wrapper .row {
    margin: 0 -10px;
  }
  .pd_fp_research_wrapper .row .rs_col {
    padding: 0 10px;
  }
  .pd_fp_research_box {
    height: auto;
  }
  .pd_article_inner .pd_article_left {
    flex: 0 0 100%;
    max-width: 100%;
    border-right: 0;
    padding-right: 0;
  }
  .pd_article_inner .pd_article_right {
    flex: 0 0 445px;
    max-width: 445px;
    padding-left: 0;
    margin-top: 40px;
  }
  .pd_article_wrapper {
    padding: 60px 0;
  }
  .pd_research_box {
    height: auto;
  }
  .pd_research_box p {
    margin-top: 20px;
  }
  .pd_research_wrapper {
    padding-bottom: 80px;
  }
  .pd_filter_date select {
    margin-top: 15px;
  }
  .pd_research_slider {
    margin-top: 30px;
  }
  .pd_upload_inner h3 {
    font-size: 22px;
    line-height: 33px;
  }
  .pd_upload_box {
    margin-top: 40px;
    padding: 60px 20px;
  }
  .pd_upload_wrapper {
    padding: 60px 0;
  }
  .pd_dashboard_wrapper {
    padding: 30px 0;
  }
  .pd_arp_wrapper {
    padding: 30px 0;
  }
  .pd_arp_inner h3 {
    font-size: 22px;
    line-height: 33px;
  }
  .pd_arp_process_box {
    margin-top: 30px;
  }
  .pd_arp_process_header .pd_arp_process {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .pd_dash_table_footer {
    row-gap: 15px;
  }
  .top-wrapper {
    align-items: flex-start;
    flex-flow: column;
    gap: 15px;
  }
  .pd_dash_search_wrapper form {
    padding: 0;
  }
  .create_profile_upload_wrap .profile_upload_wrap {
    width: 80px;
    height: 80px;
    min-height: 80px;
    min-width: 80px;
  }
  .main_article {
    margin-left: 15px;
  }
  .main_article h1 {
    font-size: 28px;
    line-height: 38px;
    margin-left: 10px;
  }
  .main_article h6 {
    font-size: 26px;
    line-height: 36px;
  }
  .right_searchbtn_bar {
    flex-flow: column;
  }
  .top-wrapper button {
    min-height: 44px;
    margin-top: 15px;
  }
}
@media (max-width: 767px) {
  .pd_fieldbtn_wrap p {
    margin-top: 50px;
  }
  .pd_fieldbtn_wrap {
    margin-bottom: 60px;
  }
  .pd_checkbox_wrap {
    margin-top: 30px;
  }
  .pd_field_wrap {
    margin-top: 25px;
  }
  .pd_lable {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 15px;
  }
  .pd_loginbtn {
    margin-top: 50px;
  }
  .pd_signup_box .pd_fieldbtn_wrap p {
    margin-top: 30px;
  }
  .pd_login_heading p {
    font-size: 18px;
    line-height: 28px;
    margin-top: 15px;
  }
  .pd_forgot_box .pd_fieldbtn_wrap {
    margin-top: 50px;
  }
  .resetpass_wrap {
    .pd_fieldbtn_wrap {
      margin-top: 50px;
    }
  }
  .pd_home_header {
    padding: 15px 0;
  }
  .pd_header_signup_btn ul li a {
    min-width: 100px;
    padding: 7px 10px;
  }
  .pd_research_heading h3 {
    font-size: 22px;
    line-height: 33px;
  }
  .pd_fp_search_wrapper {
    padding: 50px 0;
  }
  .pd_fp_search input {
    width: calc(100% - 165px);
    height: 60px;
  }
  .pd_fp_search button {
    width: 165px;
    height: 60px;
  }
  .pd_fp_research_btn {
    margin-top: 50px;
  }
  .pd_author_name_inner .pd_author_image {
    flex: 0 0 180px;
    max-width: 180px;
    height: 180px;
  }
  .pd_author_name_inner .pd_author_image_cont {
    flex: calc(100% - 180px) 1;
    max-width: calc(100% - 180px);
  }
  .pd_author_name_inner .pd_author_image_cont h3 {
    font-size: 20px;
    line-height: 30px;
  }
  .pd_author_name_inner .pd_author_image_cont h4,
  .pd_author_name_inner .pd_author_image_cont h5 {
    font-size: 20px;
    line-height: 30px;
  }
  .pd_author_name_inner {
    margin-top: 30px;
    column-gap: 20px;
  }
  .pd_author_bio p {
    font-size: 18px;
    line-height: 28px;
  }
  .pd_article_title h3 {
    font-size: 22px;
    line-height: 33px;
  }
  .pd_article_thirdtitle p {
    font-size: 18px;
    line-height: 30px;
  }
  .pd_login_heading {
    padding-top: 40px;
  }
  .pd_login_heading h3 {
    font-size: 23px;
    line-height: 34px;
  }
  .pd_arp_process_box {
    padding: 15px;
  }
  .pd_step_footer button {
    font-size: 14px;
    line-height: 20px;
    min-width: 115px;
    padding: 10px 15px;
  }
  .pd_step2_box {
    padding: 0;
  }
  .main .content {
    margin-left: 0;
  }
  .pd_login_footer {
    margin-left: 0;
  }
  .main .side {
    transform: translateX(-280px);
    transition: 0.5s;
  }
  .head_btn_wrap {
    display: flex;
    align-items: center;
  }
  .burger_button {
    margin-right: 15px;
    display: inline-block;
  }
  .main .side.slide {
    transform: translateX(0px);
    transition: 0.5s;
  }
  .burger_button.syud {
    position: absolute;
    left: 240px;
    top: -10px;
    z-index: 9999;

    .hamburger input[type="checkbox"]:checked ~ .hamburgerlines .lines {
      background: #fff;
    }
  }
  .right_searchbtn_bar {
    flex-flow: row;
  }
  .top-wrapper button {
    margin-top: 0;
  }
  .pd_dash_search_wrapper form {
    padding-right: 15px;
  }
  .slide_overlay.open {
    position: fixed;
    inset: 0;
    z-index: 9;
    background-color: #00000040;
  }
}
@media (max-width: 580px) {
  .pd_filter_header button {
    font-size: 16px;
    min-width: 110px;
    padding: 10px 10px;
  }
  .pd_filter_date button {
    font-size: 16px;
  }
  .pd_results_box {
    padding: 25px;
  }
  .pd_fp_chatting_box {
    padding: 25px;
  }
  .pd_fp_chatting_box h3 {
    font-size: 23px;
    line-height: 33px;
  }
  .pd_fp_chatting_box p {
    font-size: 23px;
    line-height: 33px;
  }
  .pd_author_name_inner {
    flex-wrap: wrap;
  }
  .pd_author_name_inner .pd_author_image_cont {
    flex: 0 0 100%;
    max-width: 100%;
    margin-top: 20px;
  }
  .pd_article_inner .pd_article_right {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 0;
    margin-top: 40px;
  }
  .pd_article_fourtitle .pd_article_figimg img {
    width: 100%;
  }
  .pd_article_fivetitle ul {
    padding-left: 20px;
  }
  .pd_research_slider .slick-slider {
    padding: 0 30px;
  }
  .pd_research_slider .slick-slider .slick-prev {
    margin-left: 15px;
  }
  .pd_research_slider .slick-slider .slick-prev {
    margin-right: 15px;
  }
  .pd_dash_table_wrapper .pd_dash_table_header {
    flex-wrap: wrap;
  }
  .pd_dash_table_wrapper .pd_dash_table_header h3 {
    flex: 0 0 100%;
  }
  .burger_button.syud {
    left: 250px;
    top: -10px;
  }
}
@media (max-width: 480px) {
  .pd_fp_search {
    display: block;
  }
  .pd_fp_search input {
    width: 100%;
  }
  .pd_fp_search button {
    width: 100%;
    margin-top: 5px;
  }
  .pd_dash_search_wrapper {
    form {
      flex-wrap: wrap;
      input {
        width: 100%;
        margin-bottom: 5px;
      }
    }
  }
  .pd_home_header .page-title {
    font-size: 18px;
  }
  .main-header .navbar {
    padding: 9.5px 15px;
  }
  .hamburger input[type="checkbox"] {
    width: 30px;
    height: 30px;
  }
  .hamburger .hamburgerlines {
    width: 25px;
    height: 20px;
    top: 5px;
    left: 3px;
    z-index: 2;
  }
  .burger_button {
    margin-right: 10px;
  }
  .main-header .account_profile .dropdown p {
    font-size: 14px;
    line-height: 14px;
    font-weight: 600;
  }
  .main-header .account_profile img {
    margin-right: 8px !important;
    height: 25px;
    width: 25px;
    position: relative;
    top: 0;
  }
  .main-header .account_profile .dropdown-menu .dropdown-item {
    padding: 5px 1rem;
    font-size: 14px;
  }
  .main-header .account_profile .dropdown-menu {
    min-width: 190px;
  }
  .right_searchbtn_bar {
    flex-flow: column;
  }
  .atr_btn.atr_btn_danger,
  .atr_btn_danger.theme_primary_btn {
    height: 44px;
    width: 250px;
    margin-top: 10px;
  }
  .main_article h1 {
    font-size: 26px;
    line-height: 36px;
  }
  .label_modify {
    font-size: 18px;
    line-height: 24px;
  }
  .userpermit_btn .theme-btn.active {
    margin-bottom: 15px;
  }
}
