.main {
  display: flex;
  flex-wrap: wrap;

  .side {
    padding-bottom: 25px;
    background-color: #000;
    width: 280px;
    overflow: hidden;
    min-height: calc(100vh - 76px);
    transition: all 0.1s ease-in-out;
    bottom: 0;
    float: none;
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    z-index: 1033;
    display: flex;
    flex-direction: column;

    &.hide_side {
      @media (max-width: 767px) {
        transform: translate3d(0px, 0px, 0px);
      }
    }
    .css-1mcnwpj-MuiList-root {
      list-style: none;
      margin: 0;
      padding: 0px 10px;
      position: relative;
      width: 100%;
      max-width: 360px;
      background-color: #000;
      margin-top: 40px;
      .active-menu {
        .MuiButtonBase-root {
          border-radius: 5px;
          background: #900;
        }
      }
    }
    span {
      margin: 0;
      color: #fff;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.5;
      letter-spacing: 0.00938em;
      display: block;
    }
    .logout_wrap {
      margin-top: 50px;
      display: flex;
      align-items: center;
      color: #fff;
      justify-content: center;
      gap: 10px;
      margin-top: auto;
    }
    .css-cveggr-MuiListItemIcon-root {
      min-width: 30px;
    }

    .logout_icn {
      display: flex;
      background: #fff;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      padding: 5px;
      border-radius: 5px;
    }
    .pd_home_logo {
      background-color: #900;
      padding: 20px;
      text-align: left;
      min-height: 100px;
      img {
        width: 110px;
      }
    }

    .logout_icn {
      display: flex;
      background: #fff;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      padding: 5px;
      border-radius: 5px;
    }
  }

  .content {
    width: calc(100% - 280px);
    background: #ededed;
    transition: margin-left 0.3s ease-in-out;
    margin-left: 280px;
    padding: 0px;
    min-height: calc(100vh - 159px);

    @media (max-width: 767px) {
      width: 100%;
    }
  }

  .main-content {
    min-height: calc(100vh - 130px);
    background-color: #fff;
  }
}

.pd_container {
  margin: 0px auto;
  padding: 0 45px;
}

.pd_inner_container {
  max-width: 1440px;
  margin: 0px auto;
  padding: 0 150px;
}

// Login scss Start
.pd_login_wrapper {
  width: 100%;
  padding: 0 15px;
}

.pd_login_box {
  max-width: 550px;
  width: 100%;
  margin: 0px auto;
}

.pd_login_heading {
  text-align: center;
  padding-top: 80px;
  margin-bottom: 10px;

  h3 {
    font-size: 30px;
    line-height: 40px;
    font-weight: $ft_bold_700;
    color: $heading-color;
  }

  p {
    font-size: 18px;
    line-height: 28px;
    font-weight: $ft_regular_400;
    color: $heading-color;
    margin-top: 10px;
  }
}

.pd_login_row {
  margin: 0 -47px;

  .col-md-6,
  .col-md-12 {
    padding: 0 47px;
  }
}

.pd_checkbox_wrap {
  @extend %theme-flex;
  margin-top: 20px;

  label {
    font-size: 16px;
    line-height: 26px;
    font-weight: $ft_regular_400;
    color: $heading-color;
    margin-left: 10px;
    margin-bottom: 0;
  }
}

.pd_fieldbtn_wrap {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 80px;

  p {
    font-size: 18px;
    line-height: 28px;
    font-weight: $ft_regular_400;
    color: $heading-color;
    margin-top: 30px;

    a {
      font-weight: $ft_bold_700;
      color: $heading-color;
    }
  }
}

.pd_loginbtn {
  margin-top: 40px;
  margin-bottom: 80px;
  .theme_primary_btn {
    &:hover {
      span > span {
        background-color: $white-color !important;
      }
    }
    span > span {
      position: relative;
      top: 3px;
    }
  }
}

.forgot_passlink {
  font-size: 14px;
  line-height: 28px;
  font-weight: 400;
  color: #000000;
  display: inline-block;
  width: 100%;
  margin-top: 15px;

  &:hover {
    color: $black-color;
  }
}

.pd_signup_box {
  max-width: 550px;
}

.pd_forgot_box {
  max-width: 550px;

  .pd_fieldbtn_wrap {
    margin-top: 40px;
  }
}

.resetpass_wrap {
  .pd_fieldbtn_wrap {
    margin-top: 40px;
  }
}

// Login scss End
// upload page scss Start
.pd_main_wrapper {
  @extend %theme-flex;
  flex-direction: column;
  min-height: 100vh;
}

.pd_upload_wrapper {
  padding: 100px 0;
}

.pd_upload_inner {
  text-align: center;

  h3 {
    font-size: 36px;
    line-height: 43px;
    font-weight: $ft_bold_700;
  }
}

.pd_upload_box {
  border: 1px solid #e4e7ec;
  @include border-radius(8px);
  max-width: 680px;
  margin: 0px auto;
  padding: 108px 25px;
  margin-top: 80px;

  label {
    cursor: pointer;
  }

  input {
    display: none;
  }

  p {
    margin-top: 12px;
    font-size: 14px;
    line-height: 20px;
    font-weight: $ft_regular_400;
    color: #667085;

    label {
      font-weight: $ft_semibold_600;
      color: #666666;
    }
  }

  h5 {
    font-size: 12px;
    line-height: 18px;
    font-weight: $ft_regular_400;
    color: #667085;
  }
}

// upload page scss End
// Dashboard page scss Start
.pd_dashboard_wrapper {
  padding: 80px 0;
}

.pd_dash_search_wrapper {
  // padding: 30px 20px 0px;
  form {
    @extend %theme-flex;
    column-gap: 36px;
    align-items: center;
    padding: 0 20px;
  }
  input {
    border: 1px solid #89899a;
    height: 44px;
    border-radius: 6px;
    padding: 10px 16px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    background-color: #fff;
    color: #000000;
    width: 100%;
    background: #fff url("../images/icons/search.svg");
    background-position: top 11px left 11px;
    background-repeat: no-repeat;
    padding-left: 35px;
    min-width: 250px;
    background-size: 20px;
    letter-spacing: 0.3px;
  }

  button {
    width: 132px;
    height: 44px;
    line-height: 44px;
    text-align: center;
    padding: 0;
    margin: 0;
    border: 1px solid #000;
    color: #000;
    @include border-radius(6px);
    font-size: 12px;
    line-height: 12px;
    font-weight: $ft_medium_500;
    background-color: $white-color;
    @include transition();

    &:hover {
      background-color: #000;
      color: $white-color;
      @include transition();
    }
  }
}

.top-wrapper {
  button {
    padding: 0;
    font-size: 16px;
    min-width: 191px;
    font-weight: 500;
    svg {
      margin-right: 8px;
      position: relative;
      top: -1px;
    }
  }
}

.pd_dash_table_wrapper {
  border: 1px solid #e4e7ec;
  background-color: #fff;
  @include border-radius(8px);
  margin-top: 30px;

  .pd_dash_table_header {
    padding: 20px 25px;
    @extend %theme-flex;
    justify-content: space-between;
    align-items: center;
    h3 {
      font-size: 18px;
      line-height: 28px;
      font-weight: $ft_medium_500;
      color: #101828;
      span {
        color: #6941c6;
        background-color: #6941c60f;
        padding: 5px 4px;
        display: inline-block;
        font-size: 14px;
        line-height: 14px;
        margin-left: 5px;
      }
    }
    .pd_dash_upload {
      button {
        font-size: 12px;
        line-height: 18px;
        font-weight: $ft_medium_500;
        color: #666666;
        background-color: #ededed;
        display: inline-block;
        padding: 2px 9px;
        margin: 0;
        @include border-radius(2px);
        cursor: pointer;
        border: 0;
        outline: none;
      }
    }
  }
}
.pd_dash_table {
  overflow-x: auto;
  width: calc(100% + 1px);
  padding: 0 15px;
  table {
    width: 100%;
    border-bottom: 0;
    min-width: 1000px;
    thead {
      tr {
        th {
          padding: 12px 22px;
          background: #f9fafb;
          color: #667085;
          font-size: 12px;
          line-height: 18px;
          text-transform: capitalize;
          font-weight: $ft_medium_500;
          border-radius: 0 !important;
          border-bottom: 0 !important;
          &:first-child {
            width: 510px;
          }
          &:last-child {
            width: 100px;
            text-align: center;
          }
        }
      }
    }
    tbody {
      tr {
        position: relative;
        td {
          padding: 6px 24px;
          font-size: 14px;
          line-height: 20px;
          font-weight: $ft_regular_400;
          color: #101828;
          border-bottom: 1px solid #e4e7ec;
          text-wrap: nowrap;
          width: auto !important;
          &:last-child {
            width: 100px;
          }
          span {
            color: #101828;
            font-weight: $ft_medium_500;
            width: 100%;
            display: inherit;
            font-size: 14px;
          }
        }
      }
    }
  }
  .table th,
  .table td {
    width: 38% !important;
    max-width: 38% !important;
    white-space: inherit;
    vertical-align: middle;
  }
}
.md_custom_checbox input[type="checkbox"] {
  display: none;
}
.md_custom_checbox input[type="checkbox"] + label:before {
  border: 1px solid #d0d5dd;
  background-color: $white-color;
  content: "\00a0";
  display: inline-block;
  font: 13px/1em sans-serif;
  height: 20px;
  text-align: center;
  margin-right: 15px;
  padding: 0;
  padding-top: 2px;
  vertical-align: top;
  width: 20px;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -khtml-border-radius: 6px;
}
.md_custom_checbox label {
  cursor: pointer;
  line-height: 16px;
  margin: 0;
}
.md_custom_checbox input[type="checkbox"]:checked + label:before {
  background: #000;
  color: #fff;
  content: "\2713";
  border: 1px solid #000;
}
.md_custom_checbox input[type="checkbox"]:checked + label:after {
  font-weight: bold;
}
.pd_dash_table_footer {
  @extend %theme-flex;
  justify-content: space-between;
  padding: 15px 24px;
  flex-wrap: wrap;
  column-gap: 15px;
  row-gap: 10px;
  .pd_table_nav {
    border: 1px solid #d0d5dd;
    background-color: $white-color;
    @include border-radius(8px);
    font-size: 14px;
    line-height: 20px;
    font-weight: $ft_medium_500;
    color: #344054;
    @extend %theme-flex;
    column-gap: 8px;
    align-items: center;
    padding: 7px 18px;
  }
  ul {
    @extend %theme-flex;
    flex-wrap: wrap;
    column-gap: 2px;
    row-gap: 10px;
    li {
      button {
        display: inline-block;
        width: 40px;
        height: 40px;
        line-height: 40px !important;
        text-align: center;
        @include border-radius(8px);
        background-color: $white-color;
        color: #667085;
        font-size: 14px;
        line-height: 20px;
        font-weight: $ft_medium_500;
        border: 0;
        cursor: pointer;
      }
      &.pagi_actived {
        button {
          background-color: #f9f5ff;
          color: #7f56d9;
        }
      }
    }
  }
}
.pd_action_btn {
  @extend %theme-flex;
  column-gap: 4px;
  justify-content: center;
  align-items: center;
  button {
    background: transparent;
    padding: 0;
    margin: 0;
    border: 0;
    outline: none;
    height: 40px;
    width: 40px;
    line-height: 40px;
    text-align: center;
  }
}
.pd_name_check {
  @extend %theme-flex;
  align-items: center;
}
.pd_dash_upload {
  input {
    display: none;
  }
}
.pd_error_message {
  color: red;
  font-size: 14px;
}
.pd_confirm_footer {
  .pd_confirm_primary {
    display: inline-block;
    background: #990000;
    text-align: center;
    transition: all 0.5s;
    outline: none;
    box-shadow: none;
    color: #fff;
    border: 1px #990000 solid;
    font-size: 16px;
    font-family: Inter;
    font-weight: 500;
    line-height: 24px;
    text-transform: capitalize;
    padding: 9px 16px;
    min-width: 89px;
    border-radius: 8px;
    &:focus {
      outline: none;
    }
    &:hover {
      color: $white-color;
      background: $black-color;
      @include transition();
      box-shadow: none;
      border: 1px solid $bg-primary-color;
    }
  }
  .pd_confirm_cancle {
    color: #344054;
    border: 1px #d0d5dd solid;
    font-size: 16px;
    font-family: Inter;
    font-weight: 500;
    line-height: 24px;
    text-transform: capitalize;
    padding: 9px 16px;
    border-radius: 8px;
    &:hover {
      background-color: #d0d5dd;
    }
  }
}
// Dashboard page scss End
// New Research Paper scss Start
.pd_arp_wrapper {
  padding: 100px 0;
}
.pd_arp_inner {
  text-align: center;
  h3 {
    font-size: 36px;
    line-height: 43px;
    font-weight: $ft_bold_700;
    color: $black-color;
  }
}
.pd_arp_process_box {
  border: 1px solid #d1d5db;
  @include border-radius(6px);
  padding: 32px;
  margin-top: 60px;
}
.pd_arp_process_header {
  background-color: #f5f5f5;
  @extend %theme-flex;
  text-align: left;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  .pd_arp_process {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 16px;
    justify-content: center;
    @extend %theme-flex;
    column-gap: 12px;
    &.active {
      border-bottom: 1px solid $black-color;
    }
    cursor: pointer;

    h5 {
      font-size: 16px;
      line-height: 16px;
      font-weight: $ft_semibold_600;
      color: #101828;
      padding-top: 2px;
    }
    h4 {
      font-size: 16px;
      line-height: 21px;
      font-weight: $ft_semibold_600;
      color: #101828;
      span {
        font-size: 12px;
        line-height: 18px;
        font-weight: $ft_regular_400;
        color: #101828;
        margin-top: 8px;
      }
    }
  }
}
.pd_step1_box {
  width: 100%;
  .pd_step1_upload {
    max-width: 300px;
    width: 100%;
    margin: 0px auto;
    margin-top: 50px;
    h3 {
      font-size: 24px;
      line-height: 34px;
      font-weight: $ft_semibold_600;
      color: #666666;
      letter-spacing: -1.5%;
    }
    p {
      font-size: 12px;
      line-height: 18px;
      font-weight: $ft_regular_400;
      color: #666666;
      letter-spacing: -1.5%;
      margin-top: 10px;
    }
    h5 {
      text-align: center;
      text-transform: uppercase;
      font-size: 12px;
      line-height: 12px;
      font-weight: $ft_semibold_600;
      color: #666666;
      margin-top: 45px;
    }
  }
}
.pd_step1_uploadbox {
  border: 1px solid #d1d5db;
  @include border-radius(8px);
  max-width: 100%;
  margin: 0px auto;
  padding: 40px 25px;
  margin-top: 15px;

  label {
    cursor: pointer;
  }

  input {
    display: none;
  }

  p {
    margin-top: 12px;
    font-size: 14px;
    line-height: 20px;
    font-weight: $ft_regular_400;
    color: #667085;
    label {
      font-weight: $ft_semibold_600;
      color: #666666;
    }
  }

  h6 {
    font-size: 12px;
    line-height: 18px;
    font-weight: $ft_regular_400;
    color: #667085;
  }
}
.pd_step1_title_field {
  margin-top: 45px;
  h4 {
    text-align: center;
    font-size: 16px;
    line-height: 26px;
    font-weight: $ft_semibold_600;
    color: #666666;
  }
  input {
    width: 100%;
    height: 40px;
    border: 1px solid #666666;
    @include border-radius(4px);
    padding: 5px 16px;
    color: #666666;
    font-size: 12px;
    line-height: 16px;
    font-weight: $ft_regular_400;
    margin-top: 16px;
  }
}
.pd_step_footer {
  @extend %theme-flex;
  justify-content: end;
  column-gap: 16px;
  row-gap: 15px;
  margin-top: 45px;
  flex-wrap: wrap;
  button {
    border: 1px solid #d9d9d9;
    @include border-radius(4px);
    text-align: center;
    font-size: 16px;
    line-height: 26px;
    font-weight: $ft_medium_500;
    color: #101828;
    background-color: $white-color;
    min-width: 140px;
    padding: 10px 46px;
    @include transition();
    &:hover {
      background-color: #f5f5f5;
      border: 1px solid #101828;
      color: #101828;
      @include transition();
    }
  }
}
.pd_step2_box {
  margin-top: 50px;
  padding: 0 50px;
}
.pd_from_header {
  text-align: center;
  margin-top: 30px;
  h3 {
    text-align: center;
    font-size: 24px;
    line-height: 34px;
    font-weight: $ft_semibold_600;
    color: #666666;
  }
  p {
    text-align: center;
    font-size: 12px;
    line-height: 16px;
    font-weight: $ft_regular_400;
    color: #101828;
    margin-top: 12px;
  }
}
.pd_from_pera {
  text-align: left;
  margin-top: 24px;
  h6 {
    font-size: 16px;
    line-height: 16px;
    font-weight: $ft_semibold_600;
    color: #101828;
  }
  p {
    font-size: 12px;
    line-height: 16px;
    font-weight: $ft_regular_400;
    color: #101828;
    margin-top: 8px;
  }
}
.pd_from_field {
  margin-top: 18px;
  text-align: left;
  label {
    width: 100%;
    font-size: 12px;
    line-height: 12px;
    font-weight: $ft_bold_700;
    color: #101828;
    text-align: left;
    margin-bottom: 8px;
    span {
      font-weight: 400;
      font-size: 10px;
    }
  }
  p {
    width: 100%;
    font-size: 12px;
    line-height: 15px;
    font-weight: $ft_regular_400;
    color: #101828;
    text-align: left;
    margin-bottom: 8px;
  }
  input {
    width: 100%;
    border: 1px solid #d0d5dd;
    height: 40px;
    @include border-radius(4px);
    padding: 5px 16px;
    font-size: 12px;
    line-height: 16px;
    font-weight: $ft_regular_400;
    @include transition();
    &:hover,
    &:focus {
      border: 1px solid #101828;
      @include transition();
    }
  }
  select {
    width: 100%;
    border: 1px solid #d0d5dd;
    height: 40px;
    @include border-radius(4px);
    padding: 5px 16px;
    font-size: 14px;
    line-height: 20px;
    font-weight: $ft_regular_400;
    background-color: $white-color;
    outline: none;
    @include transition();
    &:hover,
    &:focus {
      border: 1px solid #101828;
      @include transition();
    }
  }
  textarea {
    width: 100%;
    border: 1px solid #d0d5dd;
    @include border-radius(4px);
    padding: 10px 16px;
    font-size: 16px;
    line-height: 24px;
    font-weight: $ft_regular_400;
    background-color: $white-color;
    outline: none;
    @include transition();
    resize: none;
    &:hover,
    &:focus {
      border: 1px solid #101828;
      @include transition();
    }
  }
}
.pd_form_row {
  margin: 15px -15px 0px;
  select {
    word-wrap: normal;
    width: 100%;
    border: 1px solid #d0d5dd;
    height: 40px;
    border-radius: 4px;
    padding: 5px 16px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    transition: all 0.5s;
    &:focus {
      outline: none;
    }
  }
}
.pd_from_fieldwhite {
  // label {
  //   color: $white-c;
  // }
}
.pd_add_authorbtn {
  margin-top: 18px;
  margin-bottom: 10px;
  button {
    background-color: #ededed;
    color: #666666;
    font-size: 14px;
    line-height: 20px;
    font-weight: $ft_medium_500;
    @include border-radius(8px);
    border: 0;
    padding: 8px 24px;
    @extend %theme-flex;
    align-items: center;
    column-gap: 8px;
    @include transition();
    span {
      font-size: 20px;
    }
    &:hover {
      color: #000;
      @include transition();
    }
  }
}
.pd_nrp_upload {
  display: inline-block;
  text-align: left;
  margin-top: 16px;
  label {
    font-size: 16px;
    line-height: 24px;
    font-weight: $ft_medium_500;
    color: #666666;
    background-color: #ededed;
    padding: 10px 20px;
    margin: 0;
    @include border-radius(8px);
    cursor: pointer;
    @extend %theme-flex;
    align-items: center;
    column-gap: 8px;
  }
  input {
    display: none;
  }
}
.pd_from_field_calender {
  input {
    background: url("../images/icons/calender.png");
    background-position: left 15px top 9px;
    background-repeat: no-repeat;
    padding-left: 45px;
    background-color: #fff;
  }
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: $white-color !important;
}
.table-hover tbody tr:hover {
  background-color: $white-color !important;
}
.srv-validation-message {
  text-align: left;
  font-size: 13px;
  color: red;
}
.pd_step1_uploadbox {
  .srv-validation-message {
    text-align: center;
  }
}
.pd_from_remove_btn {
  @extend %theme-flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  gap: 10px;
  button {
    font-size: 11px;
    line-height: 12px;
    font-weight: 500;
    color: #fff;
    border: 0;
    padding: 5px 8px;
    outline: none;
    background-color: rgb(164 0 0);
    white-space: nowrap;
    border-radius: 4px;
    &:first-of-type {
      color: $black-color;
    }
    &::after {
      content: "";
      // margin-left: 10px;
    }
    &:last-of-type {
      &::after {
        display: none;
      }
    }
  }
}
.pd_ra_remove_btn {
  margin-bottom: 8px;
  button {
    font-size: 11px;
    line-height: 12px;
    font-weight: 500;
    color: red;
    border: 0;
    padding: 5px 8px;
    outline: none;
    background-color: rgba(255, 0, 0, 0.2);
    white-space: nowrap;
    border-radius: 4px;
  }
}
.keyword-tag-wrapper {
  @extend %theme-flex;
  margin-bottom: 15px;
  margin-top: 15px;
  column-gap: 10px;
  row-gap: 8px;
  flex-wrap: wrap;
}
.keyword-tag {
  background-color: #a40000;
  border: 1px solid #ededed;
  @include border-radius(8px);
  color: #fff;
  font-size: 12px;
  line-height: 20px;
  font-weight: $ft_medium_500;
  padding: 5px 14px;
  @extend %theme-flex;
  align-items: center;
  column-gap: 8px;
  row-gap: 8px;
  width: fit-content;
  margin-bottom: 5px;
  button {
    background: transparent;
    padding: 0;
    margin: 0;
    border: 0;
    font-size: 30px;
    color: #fff;
  }
}
.searchWrapper {
  border: 0 !important;
  border-radius: 4px !important;
  min-height: 40px !important;
  padding: 0 !important;
  input {
    margin-top: 0 !important;
    border: 1px solid #d0d5dd !important;
    border-radius: 4px !important;
    min-height: 40px !important;
    @include transition();
    &:hover {
      @include transition();
    }
  }
  .chip {
    background: #a40000fa !important;
    border-radius: 5px !important;
    // color: #666666;
    display: inline-flex;
    font-size: 13px;
    text-wrap: balance;
    font-weight: $ft_medium_500;
  }
}
.highlightOption,
.multiSelectContainer li:hover {
  background: #a40000 !important;

  color: #fff;
}
.react-datepicker-wrapper {
  width: 100%;
}
.file_upload_box_wrapper {
  @extend %theme-flex;
  flex-wrap: wrap;
  margin: 10px -15px;
}
.file_upload_box {
  background-color: #ededed;
  border: 1px solid #ededed;
  @include border-radius(8px);
  color: #666666;
  font-size: 14px;
  line-height: 20px;
  font-weight: $ft_medium_500;
  @extend %theme-flex;
  align-items: center;
  column-gap: 8px;
  row-gap: 8px;
  position: relative !important;
  flex: 0 0 33.33%;
  max-width: 33%;
  padding: 0 15px;
  margin-bottom: 30px;
  span {
    padding: 0;
    margin: 0;
    border: 0;
    font-size: 21px;
    color: #666666;
    position: absolute !important;
    right: 3px;
    top: -10px;
    background-color: #ccc !important;
    padding: 5px;
    display: flex;
    min-width: 25px;
    width: 25px;
    min-height: 25px;
    height: 25px;
    border-radius: 50px;
    align-items: center;
    justify-content: center;
  }
  img {
    border-radius: 8px;
    width: 100%;
    height: 152px;
    object-fit: cover;
  }
}
.keyword_chipcard {
  width: 100%;
  border: 1px solid #d0d5dd;
  border-radius: 4px;
  padding: 10px 16px;
  background-color: #ffffff;
  min-height: 128px;
  @extend %theme-flex;
  align-items: baseline;
  flex-wrap: wrap;
  column-gap: 10px;
  align-items: flex-start;
  row-gap: 10px;
  span {
    background-color: #ededed;
    border: 1px solid #ededed;
    @include border-radius(8px);
    color: #666666;
    font-size: 14px;
    line-height: 20px;
    font-weight: $ft_medium_500;
    padding: 7px 14px;
  }
}
// New Research Paper scss End
.atr_btn {
  min-width: 132px;
  text-align: center;
  padding: 12px 12px;
  border: 1px solid transparent;
  border-radius: 6px;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
  transition: all 0.5s;
  &.atr_btn_danger {
    background-color: #900 !important;
    border-color: #900 !important;
    color: #fff !important;
    &:hover,
    &:focus,
    &:active {
      background-color: #850505;
      border-color: #850505;
      color: #fff;
    }
  }
}
.theme_primary_btn {
  @extend .atr_btn;
}
.text_nowrap {
  white-space: nowrap !important;
}
.seemore_btn {
  background-color: transparent;
  border: 0;
  padding: 0;
  font-size: 12px;
  font-weight: 500;
  display: inline-block;
  text-decoration: underline;
}
.seemore_txt {
  display: inline-block !important;
  margin-right: 5px;
  width: auto !important;
  // max-width: 500px;
  // min-width: 500px;
  white-space: initial;
  .seemore_inner_txt {
    @extend .seemore_txt;
    display: inline !important;
  }
}

.pd_ra_remove_btn {
  .remove_auth_btn {
    font-size: 11px;
    line-height: 12px;
    font-weight: 500;
    color: red;
    border: 0;
    padding: 5px 8px;
    outline: none;
    background-color: rgb(255 0 0 / 20%);
    white-space: nowrap;
    border-radius: 4px;
  }
}
.pd_from_remove_btn {
  .add_row_btn {
    background-color: #000 !important;
    color: #fff !important;
    font-size: 11px;
    line-height: 12px;
    font-weight: 500;
    color: red;
    border: 0;
    padding: 5px 10px;
    outline: none;
    background-color: rgba(255, 0, 0, 0.2);
    white-space: nowrap;
    border-radius: 4px;
    min-width: 56.97px;
  }
}

.publication_title {
  // max-width: 500px;
  // min-width: 500px;
  white-space: initial;
}

.data_card {
  border: 1px solid #000;
  background-color: #fff;
  padding: 20px 30px;
  border-radius: 22px;
  cursor: pointer;
  margin-bottom: 22px;
  display: flex;
  flex-direction: column;
}
.data_title {
  font-size: 30px;
  font-weight: 500;
  line-height: 36px;
  margin-bottom: 18px;
}
.value_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.data_value {
  font-size: 50px;
  font-weight: 600;
  line-height: 56px;
}

.inner_container_wrapper {
  padding-top: 25px;
  padding-bottom: 25px;
}

.top-heading h4 {
  color: #011d22;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.top-wrapper {
  display: flex;
  justify-content: space-between;
  margin: 30px 20px 20px;
  align-items: center;
}

.main .side .css-1mcnwpj-MuiList-root a {
  padding: 6.5px 0px;
  display: block;
  text-decoration: none;
}

/***************Create your author profile css here****************/
.create_profile_upload_wrap {
  display: flex;
  align-items: center;
  max-width: 750px;
  margin: 0 auto;
  // justify-content: center;
  .profile_upload_wrap {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 1px solid #373633;
    background-color: lightgray;
    position: relative;
    .profile_input_wrap {
      top: 0;
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 1000;
      .form-control {
        width: 44px;
        height: 44px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
      }
    }
    .camera_icon_wrap {
      display: block;
      position: absolute;
      inset: 0;
    }
    label.slectpic {
      width: 35px;
      height: 35px;
      display: block;
      background: #a40000;
      position: absolute;
      right: 0px;
      bottom: -16px;
      padding: 2px 7px;
      border-radius: 50%;
      border: 2px solid #fff;
      cursor: pointer;
    }
  }
}
.main_article {
  margin-left: 14px;
  h1 {
    font-size: 46px;
    line-height: 56px;
    font-weight: 700;
    color: #000;
  }
  h6 {
    font-size: 36px;
    line-height: 46px;
    font-weight: 700;
    color: #101828;
  }
}
.profile_detail_wrap {
  margin-top: 33px;
  margin-left: auto;
  margin-right: auto;
  max-width: 750px;
}
.label_modify {
  color: #101828;
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
  margin-bottom: 10px;
  width: 100%;
}
.input_modify {
  border: 1px solid #d0d5dd;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #89899a;
  padding: 11px 10px;
  height: 48px;
  background: transparent;
  &:focus {
    box-shadow: none;
  }
}
textarea.input_modify {
  height: 213px;
}
.input_grp_wrap {
  margin-top: 24px;
  select {
    width: 100%;
    border: 1px solid #d0d5dd;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: #89899a;
    padding: 11px 10px;
    height: 48px;
    border-radius: 4px;
    background-color: transparent !important;
    &:focus {
      outline: none;
    }
  }
}
.small_text {
  color: #101828;
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
  display: block;
  margin-bottom: 16px;
}
.text_limit_wrap {
  color: #89899a;
  margin-top: 5px;
}
.publish_btn_wrap {
  margin: 68px 0px;
}

.theme-btn {
  max-width: 165px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid var(--pd-grey, #d0d5dd) !important;
  background: #f5f5f5;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 15px 18px;
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.author_formwrap {
  padding: 66px 25px 15px;
  background-color: #ededed;
}

.create_profile_upload_wrap .camera_icon_wrap img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

// Extra for */

.main .side .css-1umppoh {
  list-style: none;
  margin: 0;
  padding: 0px 10px;
  position: relative;
  width: 100%;
  max-width: 360px;
  background-color: #000;
  margin-top: 40px;
  a {
    padding: 6.5px 0px;
    display: block;
    text-decoration: none;
  }
}

.main .side .css-1umppoh a .css-yb0lig {
  margin: 0;
  color: #fff;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  display: block;
}

a.active-menu {
  border-radius: 5px;
  background: #900;
}

.pd_dash_search_wrapper.longsearch_bar {
  margin-top: 30px;
}

/* Add user css */

.adduser_formwrap {
  form.auth_form {
    padding: 0 50px;
  }
  .profile_detail_wrap {
    margin: 70px 0 40px;
    max-width: unset;
    background-color: #fff;
    padding: 40px 60px;
    border-radius: 22px;
  }
  .main_article h6 {
    font-size: 40px;
    line-height: 56px;
    margin-bottom: 20px;
  }
  .label_modify {
    font-size: 20px;
  }
  .input_grp_wrap {
    margin-top: 28px;
  }
  .atr_btn {
    min-width: 150px;
    border-radius: 4px;
    margin-top: 48px;
  }
}

.mandatory_star {
  color: #a40000;
}

/**************media css here************/
@media (max-width: 1199px) {
  .create_profile_upload_wrap .profile_upload_wrap {
    width: 120px;
    height: 120px;
  }
  .main_article h1 {
    font-size: 45px;
  }
  .main_article {
    margin-left: 30px;
  }
  .profile_detail_wrap {
    margin-top: 30px;
  }
  .input_grp_wrap {
    margin-top: 10px;
  }
  .label_modify {
    font-size: 20px;
  }
}
@media (max-width: 991px) {
  .main_article h1 {
    font-size: 33px;
    line-height: 43px;
  }
}
@media (max-width: 576px) {
  .create_profile_upload_wrap .profile_upload_wrap {
    width: 70px;
    height: 70px;
  }
  .main_article h1 {
    font-size: 18px;
    line-height: 24px;
  }
  .main_article {
    margin-left: 20px;
  }
  .main_article h6 {
    font-size: 22px;
    line-height: 25px;
  }
  .label_modify {
    font-size: 15px;
  }
  .input_modify {
    font-size: 12px;
  }
  .profile_detail_wrap {
    margin-top: 30px;
  }
}

.edit_icon {
  display: flex;
  align-items: center;
}

.multiSelectContainer li {
  padding: 10px;
  font-size: 12px;
}

.searchWrapper {
  border: 1px solid #ccc !important;
  border-radius: 4px !important;
  min-height: 22px !important;
  position: relative;
}

.multiSelectContainer input {
  border: none !important;
  background: #fff !important;
}

.searchWrapper {
  padding-top: 40px !important;
  cursor: pointer;
  &::after {
    content: "";
    position: absolute;
    top: 18px;
    right: 15px;
    font-size: 11px;
    border-top: 5px solid black;
    border-left: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 5px solid transparent;
    cursor: pointer;
    transition: 0.2s;
  }
}

.searchWrapper.rotatedropdown:after {
  transform: rotate(180deg);
  top: 12px;
  transition: 0.2s;
}

.searchWrapper input {
  position: absolute;
  inset: 0;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #900;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #900;
}

.error-message {
  color: red;
}

.loader_wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* custom radio button */

.form-group-wrap {
  margin-top: 20px;
  display: flex;
  flex-flow: wrap;
  align-items: center;
  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
  }
  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label {
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #000;
    font-size: 12px;
    text-transform: capitalize;
    margin-bottom: 0px;
  }
  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff;
  }
  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
    content: "";
    width: 10px;
    height: 10px;
    background: #900;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    transition: all 0.2s ease;
  }
  [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  .form-group {
    margin-right: 25px;
  }
}

.img_wrapper {
  display: flex;
  align-items: center;
  img {
    width: 35px;
    border-radius: 50%;
    height: 35px;
    margin-right: 10px;
    object-fit: contain;
  }
  p {
    font-size: 14px;
  }
}
.form-control:focus,
.rbt-input-multi.focus {
  box-shadow: none !important;
  // border-color: red !important;
}
.rbt-token {
  background-color: #a40000 !important;
  color: #fff !important;
}

.step_three_edit .keyword-tag {
  width: fit-content;
}
.main_wrapper_keywords {
  display: flex;
  flex-flow: wrap;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
  border-color: #000 !important;
}

.userpermit_btn {
  .theme-btn {
    color: #89899a;
    font-size: 16px;
    font-family: Inter;
    font-weight: 500;
    line-height: 16px;
    background: #f5f5f5;
    margin-right: 16px;
    &:focus {
      box-shadow: none !important;
    }
  }
  .theme-btn.active {
    color: #000;
    background: #fff;
    border: 1px solid #000 !important;
  }
}

.publish_btn_wrap {
  .theme-btn {
    border-radius: 8px;
    margin-left: 0px !important;
  }
  a {
    display: block;
    color: #990000;
    font-size: 16px;
    font-family: Inter;
    font-weight: 500;
    line-height: 24px;
    text-decoration: underline;
    margin-top: 26px;
  }
}

.react-switch-bg {
  height: 23px !important;
  width: 45px !important;
  svg {
    top: -2px !important;
    left: 5px;
    width: 15px;
  }
}

.react-switch-bg.switch-off {
  svg {
    left: unset !important;
    right: 5px;
  }
}

.toggle_switch_main.your-custom-class-off .react-switch-handle {
  transform: translateX(2px) !important;
}

.toggle_switch_main.your-custom-class-off .react-switch-bg svg {
  top: -2px !important;
  left: unset;
  right: 5px;
  width: 15px;
}

.toggle_switch_main.your-custom-class-off {
  top: 4px;
  position: relative;
}

.react-switch-handle {
  height: 20px !important;
  width: 20px !important;
  transform: translateX(24px) !important;
  top: 1px !important;
}

.main-header .account_profile .dropdown-menu {
  min-width: 240px;
  border: 1px solid #990000;
  border-radius: 10px;
  top: 15px !important;
  .dropdown-item {
    padding: 6px 1.5rem;
    color: #000;
    font-size: 16px;
  }
}

.logout_iconimg {
  position: absolute;
  right: 0;
}

.logout_iconimg img {
  margin-right: 0px !important;
}

.addfiled_wrap {
  text-align: right;
  button {
    width: auto;
    min-width: 56.97px;
  }
}

.addauthor_field button,
.addfiled_wrap button {
  background-color: #000;
  color: #fff !important;
  font-size: 11px;
  line-height: 12px;
  font-weight: 500;
  border: 0;
  padding: 5px 15px;
  outline: none;
  white-space: nowrap;
  border-radius: 4px;
  margin: 15px 0px 0px;
  width: auto;
}

.remove_btn {
  background-color: #a40000 !important;
}

/* custom checkbox */

.cust_checkbox {
  .form-group {
    display: block;
    margin-bottom: 0px;
  }

  .form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }

  .form-group label {
    position: relative;
    cursor: pointer;
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    span {
      font-size: 14px;
    }
  }

  .form-group label:before {
    content: "";
    -webkit-appearance: none;
    background-color: transparent;
    border: 1px solid #d0d5dd;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 12px;
    width: 20px;
    min-width: 20px;
    height: 20px;
    border-radius: 6px;
  }

  .form-group input:checked + label:after {
    content: "✔";
    display: block;
    color: #fff;
    position: absolute;
    top: 2px;
    left: 0;
    width: 20px;
    height: 20px;
    border-radius: 6px;
    background-color: #a40000;
    font-size: 20px;
    padding: 0 2px;
  }
}

.user_count_no h3 {
  color: black;
  font-size: 14px;
  font-family: Inter;
  font-weight: 500;
  line-height: 20px;
  span {
    color: #89899a;
  }
}

.right_searchbtn_bar {
  display: flex;
  align-items: initial;
}

/* 11 Dec 2023 */

.main-header .account_profile .dropdown-menu .dropdown-item.active {
  color: #fff !important;
  background-color: #990000;
}

.toggle_switch_main.your-custom-class-on {
  position: relative;
  top: 4px;
}

.hamburger {
  position: relative;

  input[type="checkbox"] {
    width: 40px;
    height: 40px;
    display: block;
    opacity: 0;
    cursor: pointer;
    position: relative;
    z-index: 11;
  }
  .hamburgerlines {
    width: 32px;
    height: 27px;
    position: absolute;
    top: 7px;
    left: 3px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .hamburgerlines .lines {
    display: block;
    height: 2px;
    width: 100%;
    background: #232323;
    border-radius: 10px;
  }

  .hamburgerlines .line3 {
    transform-origin: 0% 100%;
    transition: transform 0.3s ease-in-out;
  }
}
.hamburger input[type="checkbox"]:checked ~ .hamburgerlines .lines {
  background: #000;
}

.hamburger input[type="checkbox"]:checked ~ .hamburgerlines .line1 {
  transform: rotate(45deg);
}

.hamburger input[type="checkbox"]:checked ~ .hamburgerlines .line2 {
  display: none;
}

.hamburger input[type="checkbox"]:checked ~ .hamburgerlines .line3 {
  transform: rotate(-45deg);
}

.hamburgerlines .line1 {
  transform-origin: -9% 0%;
  transition: transform 0.3s ease-in-out;
}

.hamburgerlines .line3 {
  transform-origin: 0% 100%;
  transition: transform 0.3s ease-in-out;
}

.burger_button {
  display: none;
}

.rbt.select_drop:after,
.rbt-input-multi.form-control:after {
  content: "";
  position: absolute;
  top: 18px;
  right: 15px;
  font-size: 11px;
  border-top: 5px solid black;
  border-left: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 5px solid transparent;
  cursor: pointer;
  transition: 0.2s;
  cursor: pointer;
  z-index: 9;
}

.rbt-input-multi .rbt-input-main {
  margin: 0;
}

.rbt-input-multi.form-control:after {
  top: 25px;
}
.pd_dash_table .table th:first-child,
.pd_dash_table .table td:first-child {
  width: auto !important;
}

// privacy policy css

.privacy-policy-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.privacy-policy-heading {
  color: #333;
  font-size: 28px;
  margin-bottom: 20px;
}

.privacy-policy-section {
  margin-bottom: 30px;
}

h2 {
  color: #333;
  font-size: 24px;
  margin-bottom: 10px;
}

p {
  color: #666;
  font-size: 16px;
  line-height: 1.5;
}

.btn_center {
  display: flex;
  justify-content: center !important;
  margin: 20px 0px;
}

.btn_align_center {
  display: flex;
  justify-content: end;
  padding: 20px;
}

.sun-editor {
  width: calc(100% - 30px) !important;
  margin: 0 auto;
}

.bio_wrapper {
  .sun-editor {
    width: 100% !important;
  }
}

.selectedit_pic .ReactCrop {
  width: 100%;
}

.selectedit_pic .ReactCrop__child-wrapper img {
  max-height: 70vh;
  width: 100%;
  object-fit: contain;
}

.selectedit_pic .modal-body {
  padding: 0;
}

section.privacy-policy-section ul {
  padding: 0;
  li {
    list-style: disc;
  }
}

.image_round img {
  border-radius: 50%;
}

.keyword-tag button {
  font-size: 20px;
  position: relative;
  top: -2px;
}

.authorfield .row {
  margin: 1rem -15px !important;
}

.se-link-preview {
  display: none !important;
}

.pd_arp_process_box.three_proceestabs .pd_arp_process {
  flex: 0 0 33.33%;
  max-width: 33.33%;
}

/* Modal css */

.sun-editor .se-dialog .se-dialog-inner .se-dialog-header .se-modal-title,
.sun-editor .se-dialog .se-dialog-inner .se-dialog-content label {
  font-family: "Inter" !important;
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-content .se-btn-primary {
  background-color: #900 !important;
  color: #fff !important;
  border-color: #900 !important;
  text-transform: uppercase !important;
  font-family: "Inter" !important;
}

.sun-editor input.se-input-form.se-input-url:focus {
  box-shadow: none;
  border-color: #ccc;
}

.sun-editor
  .se-dialog
  .se-dialog-inner
  .se-dialog-form-footer
  .se-dialog-btn-check {
  position: relative;
  top: -2px;
}

.se-navigation.sun-editor-common {
  display: none !important;
}

.rbt-input-multi.form-control {
  padding: 0 15px 0px;
}

.rbt-input-multi.form-control:after {
  top: 19px;
}

.rbt-input-multi .rbt-input-wrapper {
  margin-top: 0 !important;
}

.rbt-input-multi .rbt-input-main {
  margin: 0;
}
.img_wrapper {
  span.user_profile {
    width: 65px !important;
    height: 65px !important;
    overflow: hidden !important;
    min-width: 65px;
    margin-right: 10px;
    border-radius: 10px !important;
    display: inline-block !important;

    img {
      width: 65px;
      height: 65px;
      border-radius: 10px;
      margin-right: 0px;
      object-fit: contain;
      min-width: 65px;
    }
  }
}
